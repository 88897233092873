import { Chemodan, Logo } from "../../icons";
import Logos from "./Logos";
import scrollImg from "./scroll.png";
import rightFon from "./rightFon.png";
import leftFon from "./leftFon.png";
import video from "./video.png";
import "./style.css";
import Flip from "react-reveal/Flip";
import { LightSpeed, Zoom } from "react-reveal";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import logo from "./logo.svg";
import str from "./v.svg";
import hand from "./hand.png";
import wtf from "./wtf.svg";
import tgf from "./tgf.svg";
import blub from "./blub.png";
import ic1 from "./1.svg";
import ic2 from "./2.svg";
import zad from "./zad.png";
import ic3 from "./3.svg";
import ic4 from "./4.svg";
import ic5 from "./5.svg";
import ic6 from "./6.svg";
import ic7 from "./7.svg";
import ic8 from "./8.svg";
import ic9 from "./9.svg";
import ic10 from "./10.svg";
import ic11 from "./11.svg";
import ic12 from "./12.svg";
import ic13 from "./13.svg";
import ic14 from "./14.svg";
import ic15 from "./15.svg";
import ic16 from "./16.svg";
import ic17 from "./17.svg";
import ic18 from "./18.svg";
import ic19 from "./19.svg";
import ic20 from "./20.svg";
import ic21 from "./21.svg";
import prem from "./prem.svg";
import chem from "./chem.svg";
import clients from "./clients.svg";
import FormL from "../../Components/Form";
import { useState } from "react";
import arris from "./arris.svg";
import vd1 from "./vd.svg";
import vd2 from "./vd2.svg";
import vd3 from "./vd3.svg";
import mobText from "./mobtext.svg";
import tgm from "./tgm.svg";
import wtm from "./wtm.svg";
export default function Posad() {
  const [pop, setPop] = useState(false);
  const Navigate = useNavigate();
  return (
    <div className="main">
      {pop && <FormL closePop={setPop}></FormL>}
      <div className="headerBody">
        <img src={leftFon} className="leftFon" alt="" />
        <img src={video} className="video" alt="" />
        <img src={rightFon} alt="" className="rightFon" />
        <header>
          {document.body.clientWidth < 640 && (
            <img src={mobText} className="mobik" alt="" />
          )}

          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <div className="headerLinks">
            <p>Отправить ТЗ на расчет</p>
            <div
              className="mess"
              onClick={() => {
                Navigate(routes.landing);
              }}
            >
              <span className="sk">●</span> Написать <br /> в Whatsapp
            </div>
            <div className="mess">
              <span className="st">● </span>
              Написать <br /> в Telegram
            </div>
            {document.body.clientWidth < 640 && (
              <img src={wtm} className="clientMob"></img>
            )}
            {document.body.clientWidth < 640 && (
              <img src={tgm} className="clientMob"></img>
            )}
            <div
              className="button-main"
              onClick={() => {
                setPop(true);
              }}
            >
              заказать звонок
            </div>
          </div>
        </header>
        <div className="headerContent">
          <div className="leftContent">
            <p className="proff">профессионально сделаем</p>

            <h1>
              <Flip top>
                <span>
                  {" "}
                  видео <img src={vd1} alt="" />
                </span>
              </Flip>
              <Flip top delay={200}>
                <span>
                  {" "}
                  брендинг <img src={vd2} alt="" />
                </span>
              </Flip>
              <Flip top delay={400}>
                <span>
                  {" "}
                  продвижение <img src={vd3} alt="" />
                </span>{" "}
              </Flip>
            </h1>

            <div className="business">
              <p>для вашего бизнеса</p>
              <img src={chem} alt="" />
            </div>
            {document.body.clientWidth > 640 && (
              <div>
                <Zoom left>
                  <div
                    className="askPrice"
                    onClick={() => {
                      setPop(true);
                    }}
                  >
                    Узнать стоимость <span className="flare"></span>
                  </div>
                </Zoom>
                <div className="butSpan">
                  <div className="lineSpan"></div>
                  <p>Оперативно расчитаем стоимость вашего проекта</p>
                </div>
              </div>
            )}
          </div>
          <div className="rightContent">
            <Logos />
            <Zoom top>
              <img src={clients} alt="" className="cl" />
            </Zoom>
            <Zoom bottom>
              <img src={prem} alt="" className="prem" />{" "}
            </Zoom>
          </div>
          {document.body.clientWidth < 640 && (
            <div>
              <img src={arris} alt="" className="arris" />
              <Zoom left>
                <div
                  className="askPrice"
                  onClick={() => {
                    setPop(true);
                  }}
                >
                  Узнать стоимость
                </div>
              </Zoom>
              <div className="butSpan">
                <div className="lineSpan"></div>
                <p>Оперативно расчитаем стоимость вашего проекта</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="scroll">
        <img src={scrollImg} alt="" />
      </div>
      <div className="posContent">
        <img src={hand} className="handPos" alt="" />
        <img src={blub} className="blub" alt="" />
        <h2>
          разработаем <br /> для вас
        </h2>
        <div className="typesCont">
          {document.body.clientWidth < 640 && (
            <div className="zadaccha">
              <img src={zad} alt="" />
              <p>
                согласно нашим исследованиям, <br />
                <b>64% потребителей</b> более склонны совершить покупку после
                просмотра видео о продукте или услуге
              </p>
            </div>
          )}
          <div className="typeCont">
            <p>видео</p>
            <div className="typeContLine"></div>
            <ul>
              <li>
                <img src={ic1} alt="" />
                <p>Рекламный ролик</p>
              </li>
              <li>
                <img src={ic2} alt="" />
                <p>Видеозаставки </p>
              </li>
              <li>
                <img src={ic3} alt="" />
                <p>Видео для соцсетей</p>
              </li>
              <li>
                <img src={ic4} alt="" />
                <p>Визуализация продуктов</p>
              </li>
              <li>
                <img src={ic5} alt="" />
                <p>Видео-презентация </p>
              </li>
              <li>
                <img src={ic6} alt="" />
                <p>3D моделирование</p>
              </li>
              <li>
                <img src={ic7} alt="" />
                <p>Анимация</p>
              </li>
              <li>
                <img src={ic8} alt="" />
                <p>Инфографика</p>
              </li>
            </ul>
          </div>
          <div className="typeCont">
            {" "}
            <p>БРЕНДИНГ</p>
            <div className="typeContLine"></div>
            <ul>
              <li>
                <img src={ic9} alt="" />
                <p>Дизайн упаковки</p>
              </li>

              <li>
                <img src={ic10} alt="" />
                <p>Нейминг </p>
              </li>

              <li>
                <img src={ic11} alt="" />
                <p>Логотип</p>
              </li>

              <li>
                <img src={ic12} alt="" />
                <p>Фирменный стиль</p>
              </li>

              <li>
                <img src={ic13} alt="" />
                <p>Брендбук</p>
              </li>

              <li>
                <img src={ic14} alt="" />
                <p>Презентации</p>
              </li>

              <li>
                <img src={ic15} alt="" />
                <p>Фирменная одежда</p>
              </li>

              <li>
                <img src={ic16} alt="" />
                <p>Фирменные аксессуары</p>
              </li>
            </ul>
          </div>
          <div className="typeCont">
            <p>ПРОДВИЖЕНИЕ</p>
            <div className="typeContLine"></div>
            <ul>
              <li>
                <img src={ic17} alt="" />
                <p>Рекламные кампании</p>
              </li>
              <li>
                <img src={ic18} alt="" />
                <p>Маркетинговые материалы</p>
              </li>
              <li>
                <img src={ic19} alt="" />
                <p>Выставки и мероприятия</p>
              </li>
              <li>
                <img src={ic20} alt="" />
                <p>Одежда и мерчандайзинг</p>
              </li>
              <li>
                <img src={ic21} alt="" />
                <p>PR и медиа сфера</p>
              </li>
            </ul>
          </div>
        </div>
        {document.body.clientWidth > 650 && (
          <div className="zadaccha">
            <img src={zad} alt="" />
            <p>
              согласно нашим исследованиям, <br />
              <b>64% потребителей</b> более склонны совершить покупку после
              просмотра видео о продукте или услуге
            </p>
          </div>
        )}
        <div className="proj">
          <div className="leftProj">
            <p>
              у вас большой проект или небольшая задача? <br />
              <b> масштаб не важен - мы готовы взяться за любую задачу</b> и
              обеспечить ее успешное выполнение!
            </p>
            <div>
              <div className="miniLine"></div>
              <p>
                и вы будете знать ориентировочную стоимость и сроки в самое
                ближайшее время
              </p>
            </div>
          </div>
          <div className="rightProj">
            <div
              className="askPrice"
              onClick={() => {
                setPop(true);
              }}
            >
              рассчитать проект
            </div>
            <div className="shmi">
              <img src={str} alt="" />
              <p>ЖМИ</p>
              <img src={str} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="posadFooter">
        <div>
          <div className="posadFooterFirst">
            <img src={logo} alt="" className="logosFooter" />
            <p>
              тел. <a href="tel: 8(800)500-94-43"> 8 (800) 500-94-43</a>
            </p>
            <span>Наши мессенджеры для связи</span>
            <div style={{ display: "flex" }}>
              <img src={wtf} alt="" className="msF" />
              <img src={tgf} alt="" className="msF" />
            </div>
          </div>
          <div className="posadFooterSecond">
            <a href="">Политика конфиденциальности</a>
          </div>
        </div>
      </div>
    </div>
  );
}
