import "./style.css";
import fon from "./fon.png";
import scheme from "./scheme.svg";
import referal from "./ref.svg";
import arrow from "./arrow.png";
import bank from "./bank.png";
import dal from "./dal.png";
import LightSpeed from "react-reveal/LightSpeed";
import strel from "./strel.svg";
import mob from "./mobFon.svg";
import { Slide, Zoom } from "react-reveal";
import { useEffect, useState } from "react";
export default function Work({ isPop }) {
  const [show, isShow] = useState(false);
  let listener = null;
  useEffect(() => {
    listener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled > 3000) {
        isShow(true);
        document.removeEventListener("scroll", listener);
      }
    });
    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, []);
  return (
    <div className="work">
      <div className="workBody">
        <div className="tapCont">
          <div className="leftTap">
            <Zoom cascade left>
              <div className="tap">ЭТАПЫ</div>
            </Zoom>
            <div className="rab">
              <Zoom top>
                {" "}
                <img src={referal} alt="" />
              </Zoom>{" "}
              <Zoom right>
                <span>РАБОТЫ</span>{" "}
              </Zoom>
            </div>
            <div className="lineGr"></div>
            <p className="example">на примере реального кейса</p>
          </div>
          <Slide right cascade>
            <div className="rightCont">
              <p>Участники</p>
              <div className="lineTap"></div>
              <ul>
                <li>
                  ВТБ <br />
                  Skolkovo
                </li>
                <div className="lineTap"></div>
                <li>Студия Бабух</li>
              </ul>
            </div>
          </Slide>
        </div>

        <img src={document.body.clientWidth > 640 ? scheme : mob} alt="" />
        <div className="chemedown">
          <img src={bank} alt="" />
        </div>
        <img src={dal} alt="" className="dalen" />
        <div className="last">
          <div>
            <Zoom left cascade>
              <p>
                Понравилось как мы{" "}
                {document.body.clientWidth < 640 && <br></br>} работаем?
              </p>
            </Zoom>
            <Zoom right cascade>
              <span>
                Напишите нам, обсудим{" "}
                {document.body.clientWidth < 640 && <br></br>} ваш проект
              </span>
            </Zoom>

            <img src={strel} alt="" />
          </div>
          <LightSpeed right>
            <div className="priceButton" onClick={() => isPop(true)}>
              НАПИСАТЬ<span className="flare"></span>
            </div>
          </LightSpeed>
        </div>
      </div>
    </div>
  );
}
