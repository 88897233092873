import about from "./about.png";
import video from "./video.png";
import earth from "./earth.png";
import icon1 from "./icons/1.svg";
import icon2 from "./icons/2.svg";
import icon3 from "./icons/3.svg";
import icon4 from "./icons/4.svg";
import icon5 from "./icons/5.svg";
import icon6 from "./icons/6.svg";
import icon7 from "./icons/7.svg";
import icon8 from "./icons/8.svg";
import icon from "./icon2.svg";
import man from "./about/man.png";
import grafic from "./about/graf.svg";
import cub from "./about/soc.png";
import Zoom from "react-reveal/Zoom";
import { Roll, Bounce, Flip, Fade } from "react-reveal";
import Rotate from "react-reveal/Rotate";
import Cons from "../../Components/Consultation/Cons";
import krug from "./krug.png";
import mob3 from "./mobil3.svg";
export default function About({ isPop }) {
  return (
    <div className="about">
      <div className="aboutContent">
        <div className="leftAbout">
          <div className="topAbout">
            <img src={krug} alt="" className="krug" />
            <Zoom left>
              <img src={about} alt="" />
            </Zoom>
            <Zoom right>
              {" "}
              <p>Бабух & Партнеры</p>
            </Zoom>
          </div>
          <h2>
            <Zoom top>
              <span>
                10 лет <img src={video} alt="" />
              </span>
            </Zoom>
            <Zoom bottom> на рынке</Zoom>
          </h2>
          <p>госзаказа и коммерческих услуг</p>
          <div className="aboutLine">
            <div className="aboutSecondLine"></div>
          </div>

          <p> Строгие регламенты не мешают нам </p>
          <div className="tvorch">
            <p>творить</p>
            <span>
              и созидать красоту{" "}
              <div>
                <svg
                  width="35"
                  height="34"
                  viewBox="0 0 35 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_764_1127)">
                    <circle cx="17.5" cy="13.5" r="11.5" fill="#53DB00" />
                  </g>
                  <g filter="url(#filter1_d_764_1127)">
                    <path
                      d="M12.8984 12.2618L16.8413 15.8003L23.2484 10.0503"
                      stroke="white"
                      stroke-width="1.78571"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_764_1127"
                      x="0.642857"
                      y="0.214286"
                      width="33.7143"
                      height="33.7143"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="3.57143" />
                      <feGaussianBlur stdDeviation="2.67857" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.0740834 0 0 0 0 0.529167 0 0 0 0 0 0 0 0 0.3 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_764_1127"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_764_1127"
                        result="shape"
                      />
                    </filter>
                    <filter
                      id="filter1_d_764_1127"
                      x="8.72935"
                      y="7.60003"
                      width="18.6858"
                      height="14.7571"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="1.78571" />
                      <feGaussianBlur stdDeviation="1.78571" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_764_1127"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_764_1127"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            </span>
          </div>
        </div>
        <div className="rightAbout">
          <Rotate duration={1500}>
            <img src={earth} alt="" className="earth" />
          </Rotate>
        </div>
      </div>
      <div className="secondAboutContent">
        <div className="secondLeftAbout">
          <p>
            Движение вашего <br /> бизнеса с помощью
          </p>{" "}
          <h3>
            <Bounce left>
              <span>
                MOTION <img src={icon} alt="" />
              </span>
            </Bounce>
            <Bounce right>
              <span>
                <div className="greenLine"></div>
                <div className="gradT">DESIGN</div>
              </span>{" "}
            </Bounce>
          </h3>
          <div className="ulsSecondAbout">
            <ul>
              <li>
                <img src={icon1} alt="" />
                <p>Рекламный ролик</p>
              </li>
              <li>
                <img src={icon2} alt="" />
                <p>Видеозаставки </p>
              </li>
              <li>
                <img src={icon3} alt="" />
                <p>Видео для соцсетей</p>
              </li>
              <li>
                <img src={icon4} alt="" />
                <p>Анимация</p>
              </li>
            </ul>

            <ul>
              <li>
                <img src={icon5} alt="" />
                <p>Визуализация продуктов</p>
              </li>
              <li>
                <img src={icon6} alt="" />
                <p>Видео-презентация </p>
              </li>
              <li>
                <img src={icon7} alt="" />
                <p>3D моделирование</p>
              </li>
              <li>
                <img src={icon8} alt="" />
                <p>Инфографика</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="secondAboutRight">
          <div className="cub">
            <img src={cub} alt="" />
          </div>
          <Rotate top right>
            <img src={man} alt="" />{" "}
          </Rotate>
        </div>
      </div>
      <div className="aboutLine mainLine">
        <div className="aboutSecondLine"></div>
      </div>
      <div className="aboutAudition">
        <div className="graf">
          <p>вовлечение</p>

          <span>аудитории</span>

          <Zoom right>
            <img src={grafic} alt="" />{" "}
          </Zoom>
        </div>
        {document.body.clientWidth > 650 ? (
          <div className="videoRaz">
            <p>
              <b>Видеоролики увеличивают</b> <br />
              желание {document.body.clientWidth < 640 && <br />} совершить
              покупку на
            </p>

            <Zoom top>
              <span>
                35 <pre>%</pre>{" "}
              </span>
            </Zoom>
          </div>
        ) : (
          <img src={mob3} style={{ width: "100%", margin: "8vw 0" }} />
        )}
      </div>
      <Cons
        isPop={isPop}
        title="Расскажите о вашей идее"
        text="мы поможем вам ее грамотно реализовать"
        color="green"
      ></Cons>
    </div>
  );
}
