import React, { useState } from "react";
import "./style.css";
import rect from "./Rectangle 599.png";
import arrow from "./arr.svg";
import vector from "./vector.svg";
import dallas from "./Vector 345.png";
import fon from "./fon.svg";
import img1 from "./1.png";
import img2 from "./2.png";
import img3 from "./3.png";
import img4 from "./4.png";
import dal from "./dal.png";
import { Roll, Bounce, Flip, Fade, Rotate, Zoom } from "react-reveal";
export const CasesSecond = () => {
  const [slideNum, setSliderNum] = useState(1);
  console.log(slideNum);
  return (
    <div className="box">
      <div className="group">
        <div className="overlap">
          <div className="overlap-wrapper">
            <div className="overlap-group">
              <div className="div">
                <Flip cascade left>
                  <div className="text-wrapper">BRANDING</div>
                </Flip>
                <div className="realize">
                  <Rotate bottom left>
                    <div className="text-wrapper-3">
                      <div className="lineWrap"></div>
                      реализация
                      <br />
                      от профи
                    </div>{" "}
                  </Rotate>
                  <Flip left>
                    <div className="text-wrapper-2">КЕЙСЫ</div>
                  </Flip>
                </div>
              </div>
              <Zoom right>
                <img
                  className="vector"
                  alt="Vector"
                  src={rect}
                  style={
                    document.body.clientWidth > 640
                      ? { left: "42vw" }
                      : { display: "none" }
                  }
                />
              </Zoom>
            </div>
          </div>
          <div className="casesCont">
            {" "}
            <div className="overlap-group-wrapper">
              <img src={dal} alt="" />
              <div className="overlap-2">
                <div className="dallas">нии восход</div>
                <div className="lineWrap"></div>
                <p className="element">
                  Новое измерение визуального стиля: Логотип, брендбук, система
                  навигации, имиджевое видео для стратегического партнера
                  государства в области информационных технологий
                </p>
                <div className="group-2">
                  <div className="overlap-group-2">
                    <div className="rectangle" />
                  </div>
                  <div className="text-wrapper-4">
                    Подробнее{" "}
                    <img className="vector-7" alt="Vector" src={arrow} />
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="mobilC">
              <div
                className="rightCases"
                style={{
                  left: (slideNum - 1) * -99 + "vw",
                }}
              >
                <div className="div-wrapper">
                  <img src={img1} alt="" />
                  <div className="overlap-3">
                    <div className="text-wrapper-5">
                      Adventure Lounge SKOLKOVO
                    </div>
                    <div className="lineWrap"></div>
                    <p
                      className="p"
                      style={
                        document.body.clientWidth < 640
                          ? {
                              height: "4.5vw",
                            }
                          : { height: "1.5vw" }
                      }
                    >
                      Создание Brand ID для подразделение Сколково
                    </p>
                    <div className="group-5">
                      <div className="overlap-group-3">
                        <div className="rectangle-2" />
                      </div>
                      <div className="text-wrapper-6">
                        Подробнее{" "}
                        <img className="vector-7" alt="Vector" src={arrow} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-wrapper">
                  <img src={img2} alt="" />
                  <div className="overlap-3">
                    <div className="text-wrapper-5">Всероссийский форум</div>
                    <div className="lineWrap"></div>
                    <p className="p">
                      Brand ID: логотип, брендбук, баннеры и многое другое
                    </p>
                    <div className="group-5">
                      <div className="overlap-group-3">
                        <div className="rectangle-2" />
                      </div>
                      <div className="text-wrapper-6">
                        Подробнее{" "}
                        <img className="vector-7" alt="Vector" src={arrow} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-wrapper">
                  <img src={img3} alt="" />
                  <div className="overlap-3">
                    <div className="text-wrapper-5">Конгресс-выставка</div>
                    <div className="lineWrap"></div>
                    <p className="p">
                      Brand ID для XVII международной конгресс-выставки
                    </p>
                    <div className="group-3">
                      <div className="overlap-group-3">
                        <div className="rectangle-2" />
                      </div>
                      <div className="text-wrapper-6">
                        Подробнее{" "}
                        <img className="vector-7" alt="Vector" src={arrow} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-wrapper">
                  <img src={img4} alt="" />
                  <div className="overlap-3">
                    <div className="text-wrapper-5">втб&skolkovo </div>
                    <div className="lineWrap"></div>
                    <p className="p">
                      Все визуальные составляющие для проекта Data Fusion
                    </p>
                    <div className="group-3">
                      <div className="overlap-group-3">
                        <div className="rectangle-2" />
                      </div>
                      <div className="text-wrapper-6">
                        Подробнее{" "}
                        <img className="vector-7" alt="Vector" src={arrow} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {document.body.clientWidth < 640 && (
              <div className="sliderButtons">
                <div
                  onClick={() => {
                    slideNum != 1 && setSliderNum(slideNum - 1);
                  }}
                  style={
                    slideNum != 1
                      ? { border: "3px solid #A4FF00", color: "#fff" }
                      : {}
                  }
                >
                  ←
                </div>
                <div
                  onClick={() => {
                    slideNum != 4 && setSliderNum(slideNum + 1);
                  }}
                  style={
                    slideNum != 4
                      ? { border: "3px solid #A4FF00", color: "#fff" }
                      : {}
                  }
                >
                  →
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
