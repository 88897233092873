import "./style.css";
import str from "./str.png";
import logo from "../Posad/logo.svg";
import wtf from "../Posad/wtf.svg";
import tgf from "../Posad/tgf.svg";
export default function Thanks() {
  return (
    <div className="thanksPage">
      <h1>Поздравляем!</h1>
      <p className="pol">У вас все получилось</p>
      <p className="nextTime">
        <b> В ближайшее время</b> <br />
        Ваш бизнес преобразится
      </p>
      <div className="watchWork">
        А пока вы можете посмотреть наши выполенные работы
      </div>
      <div className="watchButton">
        <img src={str} className="stLeft" alt="" />
        <div className="askPrice">Смотреть работы</div>
        {document.body.clientWidth > 640 && (
          <img src={str} className="stRight" alt="" />
        )}
      </div>
      <div className="posadFooter">
        <div>
          <div className="posadFooterFirst">
            <img src={logo} alt="" className="logosFooter" />
            <p>
              тел. <a href="tel: 8(800)500-94-43"> 8 (800) 500-94-43</a>
            </p>
            <span>Наши мессенджеры для связи</span>
            <div style={{ display: "flex" }}>
              <img src={wtf} alt="" className="msF" />
              <img src={tgf} alt="" className="msF" />
            </div>
          </div>
          <div className="posadFooterSecond">
            <a href="">Политика конфиденциальности</a>
          </div>
        </div>
      </div>
    </div>
  );
}
