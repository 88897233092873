import arrow from "./arrow.svg";
import "./style.css";
import LightSpeed from "react-reveal/LightSpeed";
import { Roll, Bounce, Flip, Fade } from "react-reveal";
import three from "./three.png";
export default function Cons({ title, text, color, isPop }) {
  return (
    <div
      className="component"
      style={
        color && document.body.clientWidth > 640 && color == "nos"
          ? {
              background: "url(" + three + ")",
              backgroundSize: "100%",
              borderRadius: "3vw",
            }
          : {}
      }
    >
      <LightSpeed left>
        <div className="priceButton" onClick={() => isPop(true)}>
          Получить консультацию<span className="flare"></span>
        </div>
      </LightSpeed>
      <div className="idea">
        <p>{title}</p>
        <span>
          {document.body.clientWidth > 640 && <img src={arrow} alt="" />}
          {text}
        </span>
      </div>
    </div>
  );
}
