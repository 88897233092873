import img1 from "./1.svg";
import img2 from "./2.svg";
import img3 from "./3.svg";
import img4 from "./4.svg";
import img5 from "./5.svg";
import img6 from "./6.svg";
import img7 from "./7.svg";
import "./style.css";
import img8 from "./8.svg";
import big from "./big.png";
import bigMob from "./mobBig.png";
import book from "./book.png";
import stand1 from "./firstStand.png";
import stand2 from "./stand2.png";
import stand3 from "./stand3.png";
import stand4 from "./stand4.png";
import Pulse from "react-reveal/Pulse";
import stand5 from "./stand5.png";
import light from "./light.png";
import girl from "./girl.png";
import pl from "./pl.png";
import { Fade, Flip, Zoom, Rotate, Bounce } from "react-reveal";
import { useState } from "react";
import { useEffect } from "react";
import mob4 from "../../pages/Landing/mob4.svg";
import Cons from "../Consultation/Cons";
export default function BrandingDesign({ isPop }) {
  //   const [counters, setCounters] = useState(1);
  //   useEffect(() => {
  //     const timer = setInterval(() => {
  //       setCounters(counters + 1);
  //     }, 3000);
  //     return () => clearInterval(timer);
  //   });
  return (
    <div className="BrandingDesign">
      <div className="brandingDesignBody">
        <Rotate top left>
          <div className="branding">BRANDING</div>
        </Rotate>

        <div className="design">
          {" "}
          <Zoom delay={200} left>
            <div className="structure">
              <div className="lineWrap"></div>
              грамотная <br /> структура
            </div>
          </Zoom>
          <Rotate bottom right>
            <div className="designText">DESIGN</div>{" "}
          </Rotate>
        </div>

        <p>
          {" "}
          <Zoom left cascade delay={300}>
            Ваш ключ к узнаваемости и лояльности
          </Zoom>
        </p>
        <div className="bigImage">
          {document.body.clientWidth > 640 && (
            <div>
              <img src={big} alt="" className="bigFon" />
              <img src={light} className="lights" alt="" />
              <Zoom delay={200} left>
                <img src={stand1} alt="" className="stand1 stand" />
              </Zoom>{" "}
              <Bounce top delay={200}>
                <img src={stand2} alt="" className="stand2 stand" />
              </Bounce>{" "}
              <Bounce top delay={400}>
                {" "}
                <img src={stand3} alt="" className="stand3 stand" />
              </Bounce>
              <Bounce top delay={500}>
                {" "}
                <img src={stand4} alt="" className="stand4 stand" />
              </Bounce>
              <Bounce top delay={600}>
                {" "}
                <img src={stand5} alt="" className="stand5 stand" />
              </Bounce>
              <Rotate bottom left delay={300}>
                <div>
                  <img src={book} className="book" alt="" />
                </div>
              </Rotate>
            </div>
          )}

          <ul>
            <li>
              <img src={img1} alt="" />
              <p>Нейминг</p>
            </li>
            <li>
              <img src={img2} alt="" />
              <p>Логотип</p>
            </li>
            <li>
              <img src={img3} alt="" />
              <p>Фирменный стиль</p>
            </li>
            <li>
              <img src={img4} alt="" />
              <p>Брендбук</p>
            </li>
            <li>
              <img src={img5} alt="" />
              <p>Дизайн упаковки</p>
            </li>
            <li>
              <img src={img6} alt="" />
              <p>Система навигации</p>
            </li>
            <li>
              <img src={img7} alt="" />
              <p>Фирменная атрибутика</p>
            </li>
            <li>
              <img src={img8} alt="" />
              <p>Социальные сети</p>
            </li>
          </ul>
        </div>
        {document.body.clientWidth < 640 && (
          <div>
            <img src={bigMob} alt="" className="bigMob" />
          </div>
        )}
        <div className="aboutLine">
          <div className="aboutSecondLine"></div>
        </div>
        <div className="noDemp">
          <img src={girl} alt="" />
          <div className="rightDemp">
            <p>Скажите нет</p>
            <span>конкуренции по цене и демпингу!</span>
            {document.body.clientWidth > 650 ? (
              <div className="videoRaz">
                <Flip top>
                  <p>
                    <b>
                      по статистике Лояльность{" "}
                      {document.body.clientWidth < 640 && <br />} клиентов к
                      бренду влияет на
                    </b>{" "}
                    <br />
                    их покупательские решения
                  </p>
                </Flip>
                <Zoom top>
                  <span>
                    83 <pre>%</pre>{" "}
                  </span>
                </Zoom>
              </div>
            ) : (
              <img src={mob4} style={{ width: "100%", margin: "8vw 0" }} />
            )}

            <div className="dalp">
              Бабух & Партнеры <img src={pl} alt="" />
            </div>
          </div>
        </div>
        <Cons
          isPop={isPop}
          title="опишите нам ваш проект"
          text="и мы начнем анализ вашего бренда"
        ></Cons>
      </div>
    </div>
  );
}
