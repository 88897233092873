import img1 from "./1.png";
import img2 from "./2.png";
import img3 from "./3.png";
import img4 from "./4.png";
import img5 from "./5.png";
import img6 from "./6.png";
import img7 from "./7.png";
import img8 from "./8.png";
import img9 from "./9.png";
import img10 from "./10.png";
import img11 from "./11.png";
import img12 from "./12.png";
import img13 from "./13.png";
import img14 from "./14.png";
import img15 from "./15.png";
import img16 from "./16.png";
import img17 from "./17.png";
import img18 from "./18.png";
import Zoom from "react-reveal/Zoom";
const arr = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
];
const arr2 = [img1, img2, img3, img4, img5, img6, img7, img8, img9];
export default function Logos() {
  return (
    <div className="logosTable">
      {document.body.clientWidth > 640 &&
        arr.map((em, index) => {
          return (
            <Zoom delay={100 * index}>
              <img key={index} src={em}></img>
            </Zoom>
          );
        })}
      {document.body.clientWidth < 640 &&
        arr2.map((em, index) => {
          return (
            <Zoom delay={100 * index}>
              <img key={index} src={em}></img>
            </Zoom>
          );
        })}
    </div>
  );
}
