import { useState } from "react";
import InputMask from "react-input-mask";
import "./style.css";
export default function FormL({ closePop }) {
  const [tel, setTel] = useState("");
  const [name, setName] = useState("");
  function CloseMe(e) {
    if (e.target.id == "popBody") {
      closePop(false);
    }
  }
  return (
    <div className="popForm" onClick={CloseMe} id="popBody">
      <div className="popFormBody">
        <p className="formZ">
          <span>Заполните</span> <br />
          форму
        </p>
        <p className="svz">Мы свяжемся с вами через 10 минут</p>
        <InputMask
          mask="+7 999 999 99 99"
          maskChar={null}
          value={tel}
          onChange={(e) => setTel(e.target.value)}
          placeholder="Телефон*"
        />
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Ваше имя"
        />
        <div className="sendFrom">отправить</div>
      </div>
    </div>
  );
}
