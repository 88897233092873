import { Roll, Bounce, Flip, Fade, Rotate, Zoom } from "react-reveal";
import "./style.css";
import girl from "./girl.png";
import rightArrow from "./rightArrow.svg";
import img1 from "./1.svg";
import img2 from "./2.svg";
import img3 from "./3.svg";
import img4 from "./4.svg";
import img5 from "./5.svg";
import ic1 from "./ic1.svg";
import ic2 from "./ic2.svg";
import ic3 from "./ic3.svg";
import ic4 from "./ic4.svg";
import ic5 from "./ic5.svg";
import ic6 from "./ic6.svg";
import Cons from "../Consultation/Cons";
import qdr1 from "./1.png";
import qdr2 from "./2.png";
import qdr3 from "./3.png";
import qdr4 from "./4.png";
import qdr5 from "./5.png";
import qdr6 from "./6.png";
import qdr7 from "./7.png";
import qdr8 from "./8.png";
import qdr9 from "./9.png";
import qdr10 from "./10.png";
import qdr11 from "./11.png";
import qdr12 from "./12.png";
import qdr13 from "./13.png";
import qdr14 from "./14.png";
import qdr15 from "./15.png";
import qdr16 from "./16.png";
import an from "./an.svg";
import text from "./text.svg";
import test from "./test.png";
import man from "./man.png";
import channel from "./channel.png";
import mobTitle from "./mobTitle.svg";
import dialog from "./dialog.svg";
import { useState } from "react";
import title1 from "./title1.svg";
import title2 from "./title2.svg";
const arr = [
  qdr1,
  qdr2,
  qdr3,
  qdr4,
  qdr5,
  qdr6,
  qdr7,
  qdr8,
  qdr9,
  qdr10,
  qdr11,
  qdr12,
  qdr13,
  qdr14,
  qdr15,
  qdr16,
];
export default function Promo({ isPop }) {
  const [sliderNum, setSliderNum] = useState(false);
  return (
    <div className="promoComp">
      <div className="promoCompBody">
        {document.body.clientWidth > 640 && (
          <div>
            <Rotate top left>
              <div className="branding">РАЗВИТИЕ</div>
            </Rotate>
            <div className="design">
              {" "}
              <Zoom delay={200} left>
                <div className="structure">
                  <div className="lineWrap"></div>
                  НАСТРОЙКА <br />И ТЕСТЫ
                </div>
              </Zoom>
              <Rotate bottom right>
                <div className="designText">PROMOTION</div>{" "}
              </Rotate>
            </div>
          </div>
        )}
        {document.body.clientWidth < 640 && (
          <img src={title1} style={{ width: "100%" }} />
        )}
        <p className="promoTxt">Делаем бренд узнаваемым, а продажи успешными</p>
        <div className="promoContent">
          <img src={channel} className="getCh" alt="" />
          <ul>
            <li>
              <img src={img1} alt="" />
              <p>ТВ и радио</p>
            </li>
            <li>
              <img src={img2} alt="" />
              <p>Выставки и мероприятия</p>
            </li>
            <li>
              <img src={img3} alt="" />
              <p>PR и медиа сфера</p>
            </li>
            <li>
              <img src={img4} alt="" />
              <p>Маркетинговые материалы</p>
            </li>
            <li>
              <img src={img5} alt="" />
              <p>
                Рекламные кампании
                <br />в интернете “под ключ”
              </p>
            </li>
          </ul>
          <img src={girl} alt="" />
        </div>
        <div className="aboutLine">
          <div className="aboutSecondLine"></div>
        </div>
        <div className="an">
          <div className="leftAn">
            <p>Продвижение</p>
            <span>- эффективный диалог</span>
            <div>с вашей аудиторией</div>
          </div>
          <img src={document.body.clientWidth > 640 ? an : dialog} alt="" />
        </div>
        <Cons
          isPop={isPop}
          color={"nos"}
          title="расскажем вам как влияет"
          text="PROMOTION на развитие вашего бренда"
        ></Cons>
        <img
          src={document.body.clientWidth < 640 ? mobTitle : text}
          className="imgs"
          alt=""
        />
        <div
          className="arrImg"
          style={sliderNum ? { transform: "translateX(-98vw)" } : {}}
        >
          {arr.map((em, index) => {
            return (
              <Zoom top>
                <img src={em} key={index} className="zoomMe" />
              </Zoom>
            );
          })}
        </div>{" "}
        {document.body.clientWidth < 640 && (
          <div className="sliderButtons">
            <div
              onClick={() => {
                setSliderNum(false);
              }}
              style={
                sliderNum == true
                  ? { background: "#806BFF", color: "#fff" }
                  : { color: "black" }
              }
            >
              ←
            </div>
            <div
              onClick={() => {
                setSliderNum(true);
              }}
              style={
                sliderNum == false
                  ? { background: "#806BFF", color: "#fff" }
                  : { color: "black" }
              }
            >
              →
            </div>
          </div>
        )}
        {document.body.clientWidth > 640 && (
          <div className="bus">
            <Rotate top left>
              <div className="branding">ПРЕОБРАЗИТЕ</div>
            </Rotate>

            <div className="design">
              {" "}
              <Zoom delay={200} left>
                <div className="structure">
                  <div className="lineWrap"></div>
                  проведем <br />
                  вас к росту
                </div>
              </Zoom>
              <Rotate bottom right>
                <div className="designText">СВОЙ БИЗНЕС!</div>{" "}
              </Rotate>
            </div>
          </div>
        )}
        {document.body.clientWidth < 640 && (
          <img src={title2} style={{ width: "100%", marginTop: "10vw" }} />
        )}
        <div className="promoContent withButton">
          <ul>
            <li>
              <img src={ic1} alt="" />
              <p>Увеличение продаж</p>
            </li>{" "}
            <li>
              <img src={ic2} alt="" />
              <p>Узнаваемость бренда</p>
            </li>{" "}
            <li>
              <img src={ic3} alt="" />
              <p>Рост капитализации компании</p>
            </li>{" "}
            <li>
              <img src={ic4} alt="" />
              <p>Выход на новые рынки</p>
            </li>{" "}
            <li>
              <img src={ic5} alt="" />
              <p>Оптимизация расходов на продвижение</p>
            </li>{" "}
            <li className="noBord">
              <img src={ic6} alt="" />
              <p>Системный рост и развитие</p>
            </li>
            <li>
              <div className="priceButton" onClick={() => isPop(true)}>
                Оставить заявку<span className="flare"></span>
              </div>
            </li>
          </ul>
          <img className="newPromo" src={man} alt="" />
          <img className="testic" src={test} alt="" />
          {document.body.clientWidth > 640 && (
            <img src={rightArrow} className="arrowAbs" alt="" />
          )}
        </div>
      </div>
    </div>
  );
}
