import { useEffect, useState } from "react";
import "./style.css";
import logos from "./logo.svg";
import tg from "./tg.svg";
import wt from "./wt.svg";
import "../../fonts2/stylesheet.css";
import "../../gilroy/stylesheet.css";
import arrow from "./arrow.png";
import Zoom from "react-reveal/Zoom";
import aaaa from "./aaa.png";
import Slide from "react-reveal/Slide";
import hd from "./hd.png";
import Flip from "react-reveal/Flip";
import Bounce from "react-reveal/Bounce";
import arrow2 from "./arrow2.svg";
import About from "./About";
import ic1 from "./icons/ic1.svg";
import ic2 from "./icons/ic2.svg";
import raz from "../../Components/Cases2/hah.png";
import { Cases } from "../../Components/Cases";
import BrandingDesign from "../../Components/BrandingDesign";
import { CasesSecond } from "../../Components/Cases2";
import Promo from "../../Components/Promotion";
import Work from "../../Components/Work";
import Footer from "../../Components/Footer";
import FormL from "../../Components/Form";
import Banana from "./banana.png";
import krest from "./krest.png";
import topImg from "../../Components/fons/top.png";
import bottomImg from "../../Components/fons/bottom.png";
import desc from "./desc.png";
import klik from "./klic.svg";
export default function Landing() {
  const [logo, setLogo] = useState(false);
  const [pop, isPop] = useState(false);
  const [model, isModel] = useState(false);
  const onProgress = (event) => {
    const progressBar = event.target.querySelector(".progress-bar");
    const updatingBar = event.target.querySelector(".update-bar");
    updatingBar.style.width = `${event.detail.totalProgress * 100}%`;
    if (event.detail.totalProgress === 1) {
      progressBar.classList.add("hide");
      event.target.removeEventListener("progress", onProgress);
    } else {
      progressBar.classList.remove("hide");
    }
  };
  // useEffect(() => {
  //   document
  //     .querySelector("model-viewer")
  //     .addEventListener("progress", onProgress);
  // }, []);

  return (
    <div
      className="Landing"
      style={
        document.body.clientWidth > 640
          ? { transform: "scale(1.35) translateY(118vw)" }
          : {}
      }
    >
      {/*   {pop && <FormL closePop={isPop}></FormL>} */}
      <div className="landHeader">
        <div className="landHeaderBody">
          <img src={logos} alt="" />
          <p>
            {" "}
            <span>Более 500 </span> <br />
            выполненных проектов
          </p>
          <span className="headLine"></span>
          <div>
            <p>
              Отправить ТЗ {document.body.clientWidth < 640 && <br></br>} на
              расчет
            </p>
            <a href="https://t.me/BabukhpartnersMsk" target="_blank">
              <img src={tg} alt="" />
            </a>
            <a href="https://wa.me/89160324032" target="_blank">
              <img src={wt} alt="" />
            </a>

            <a href="tel:84382425892">
              8 438 2425892
              {document.body.clientWidth < 640 && <p>Отправить ТЗ на расчет</p>}
            </a>
          </div>
        </div>

        <div className="landHeaderModel">
          <div className="leftHead">
            <div className="gradBord">
              <h3>
                <span>Профессионально</span> сделаем на уровне{" "}
                {document.body.clientWidth < 640 && <br></br>} гос. компаний для
                Вашего бизнеса
              </h3>
            </div>
            {document.body.clientWidth < 640 && (
              <img src={klik} className="klik" alt="" />
            )}
            <h1>
              {" "}
              <span className="check">
                {" "}
                <Zoom left cascade duration={700}>
                  MOTION
                </Zoom>
                <Zoom delay={300}>
                  <div
                    className="sss"
                    style={
                      logo
                        ? { rotate: "180deg", translate: "0, 1vw)" }
                        : { rotate: "0deg", translate: "0, 0vw)" }
                    }
                    onClick={() => setLogo(!logo)}
                  >
                    <svg
                      style={
                        document.body.clientWidth < 640
                          ? { display: "none" }
                          : {}
                      }
                      xmlns="http://www.w3.org/2000/svg"
                      width="62"
                      height="76"
                      viewBox="0 0 62 76"
                      fill="none"
                    >
                      <g filter="url(#filter0_i_764_64)">
                        <rect
                          x="16"
                          y="75.5713"
                          width="56.5714"
                          height="30"
                          rx="15"
                          transform="rotate(-90 16 75.5713)"
                          fill="#282C31"
                        />
                      </g>
                      <rect
                        x="16.25"
                        y="75.3213"
                        width="56.0714"
                        height="29.5"
                        rx="14.75"
                        transform="rotate(-90 16.25 75.3213)"
                        stroke="url(#paint0_linear_764_64)"
                        stroke-width="0.5"
                      />
                      <g filter="url(#filter1_ddd_764_64)">
                        <rect
                          x="18.5703"
                          y="47.2856"
                          width="24.8571"
                          height="24.8571"
                          rx="12.4286"
                          transform="rotate(-90 18.5703 47.2856)"
                          fill="url(#paint1_linear_764_64)"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_i_764_64"
                          x="16"
                          y="19"
                          width="33"
                          height="60.5713"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dx="3" dy="4" />
                          <feGaussianBlur stdDeviation="3.5" />
                          <feComposite
                            in2="hardAlpha"
                            operator="arithmetic"
                            k2="-1"
                            k3="1"
                          />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.61 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="shape"
                            result="effect1_innerShadow_764_64"
                          />
                        </filter>
                        <filter
                          id="filter1_ddd_764_64"
                          x="0.570312"
                          y="0.428711"
                          width="60.8555"
                          height="60.8569"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_764_64"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="-4" />
                          <feGaussianBlur stdDeviation="9" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.635593 0 0 0 0 1 0 0 0 0 0 0 0 0 0.31 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="effect1_dropShadow_764_64"
                            result="effect2_dropShadow_764_64"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="-2" />
                          <feGaussianBlur stdDeviation="1.5" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.62 0 0 0 0 1 0 0 0 0 0 0 0 0 0.36 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="effect2_dropShadow_764_64"
                            result="effect3_dropShadow_764_64"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect3_dropShadow_764_64"
                            result="shape"
                          />
                        </filter>
                        <linearGradient
                          id="paint0_linear_764_64"
                          x1="16"
                          y1="84.5713"
                          x2="73.4649"
                          y2="92.4865"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#5B5C56" />
                          <stop
                            offset="1"
                            stop-color="#828770"
                            stop-opacity="0.31"
                          />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_764_64"
                          x1="30.9989"
                          y1="47.2856"
                          x2="30.9989"
                          y2="72.1428"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#A4FF00" />
                          <stop offset="1" stop-color="#69A400" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </Zoom>
              </span>{" "}
              <div>
                <Zoom left duration={500} delay={100}>
                  <span className="brandingPromo">
                    BRANDING <br /> PROMO
                  </span>
                </Zoom>{" "}
                <Zoom right delay={300}>
                  <span className="grad">DESIGN </span>{" "}
                </Zoom>
              </div>
            </h1>
            {document.body.clientWidth < 650 && (
              <img
                src={Banana}
                className="banana"
                onClick={() => isModel(true)}
              />
            )}
            <div className="achiev">
              <div className="twoAchiev">
                <div className="achItem">
                  <div className="achBody">
                    <img src={ic1} alt="" />
                  </div>
                </div>
                <div className="achText">
                  <p>
                    <span>+</span>160% <br />
                    <article>Вовлеченность</article>
                  </p>
                </div>{" "}
                <div className="achItem">
                  <div className="achBody">
                    <img src={ic2} alt="" />
                  </div>
                </div>
                <div className="achText">
                  <p>
                    <span>+</span>47% <br />
                    <article>Конверсия</article>
                  </p>
                </div>
                {document.body.clientWidth > 640 && (
                  <img src={arrow2} className="arrow2" alt="" />
                )}
              </div>
            </div>
            <div className="priceLand">
              <Zoom delay={400} top>
                <div className="priceButton" onClick={() => isPop(true)}>
                  Узнать стоимость<span className="flare"></span>
                </div>
              </Zoom>

              <div className="priceLandText">
                <div>
                  {document.body.clientWidth > 640 && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                      fill="none"
                    >
                      <g filter="url(#filter0_i_764_35)">
                        <rect
                          width="23"
                          height="23"
                          rx="3.48485"
                          transform="matrix(-1 0 0 1 23 0)"
                          fill="#601FEB"
                        />
                      </g>
                      <path
                        d="M5.58674 11.2956C5.31456 11.0234 5.31456 10.5821 5.58674 10.3099L10.0222 5.87441C10.2944 5.60223 10.7357 5.60223 11.0079 5.87441C11.2801 6.1466 11.2801 6.58789 11.0079 6.86008L7.06523 10.8027L11.0079 14.7454C11.2801 15.0176 11.2801 15.4589 11.0079 15.7311C10.7357 16.0032 10.2944 16.0032 10.0222 15.7311L5.58674 11.2956ZM16.9219 11.4997H6.07957V10.1058H16.9219V11.4997Z"
                        fill="black"
                      />
                      <defs>
                        <filter
                          id="filter0_i_764_35"
                          x="0"
                          y="-9.75758"
                          width="23"
                          height="32.7576"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="-17.4242" />
                          <feGaussianBlur stdDeviation="4.87879" />
                          <feComposite
                            in2="hardAlpha"
                            operator="arithmetic"
                            k2="-1"
                            k3="1"
                          />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.9625 0 0 0 0 0.966667 0 0 0 0 1 0 0 0 1 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="shape"
                            result="effect1_innerShadow_764_35"
                          />
                        </filter>
                      </defs>
                    </svg>
                  )}

                  <p>Ваш проект</p>
                </div>
                <p>в шаге от реализации</p>
              </div>
            </div>
          </div>
          <div
            className="rightLand"
            style={
              !model && document.body.clientWidth < 640
                ? { display: "none" }
                : model && document.body.clientWidth < 640
                ? { display: "block" }
                : {}
            }
          >
            {document.body.clientWidth < 640 && (
              <img
                src={krest}
                className="krest"
                onClick={() => isModel(false)}
              ></img>
            )}
            <Bounce right>
              <model-viewer
                src="/ar/model.glb"
                poster={document.body.clientWidth < 640 ? "/banana.png" : desc}
                camera-controls
                shadow-intensity="1"
                disable-pan
                id="modelV"
              ></model-viewer>
              <img src={arrow} className="arrow" alt="" />
            </Bounce>
          </div>
        </div>
      </div>
      {document.body.clientWidth < 640 && (
        <img
          src={topImg}
          className="razdelit"
          style={{ width: "100%", display: "block" }}
        ></img>
      )}
      <About isPop={isPop}></About>
      {document.body.clientWidth < 640 && (
        <img
          src={bottomImg}
          className="razdelit"
          style={{ width: "100%", backgroundColor: "#F4F9FD" }}
        ></img>
      )}
      <Cases isPop={isPop}></Cases>
      {document.body.clientWidth < 640 && (
        <img
          src={topImg}
          style={{ width: "100%", display: "block", background: "#f4f9fd" }}
        ></img>
      )}
      <BrandingDesign isPop={isPop}></BrandingDesign>

      {document.body.clientWidth < 640 ? (
        <img
          src={bottomImg}
          style={{
            width: "100%",
            display: "block",
            backgroundColor: "#F4F9FD",
          }}
          className="razdelit"
        ></img>
      ) : (
        <img
          src={raz}
          style={{
            width: "100%",
            display: "block",
            backgroundColor: "#F4F9FD",
          }}
        ></img>
      )}
      <CasesSecond isPop={isPop}></CasesSecond>
      {document.body.clientWidth < 640 && (
        <img src={topImg} style={{ width: "100%", display: "block" }}></img>
      )}
      <Promo isPop={isPop} />
      {document.body.clientWidth < 640 ? (
        <img
          src={bottomImg}
          style={{
            width: "100%",
            display: "block",
            backgroundColor: "#F4F9FD",
          }}
          className="razdelit"
        ></img>
      ) : (
        <img
          src={aaaa}
          style={{ width: "100%", display: "block", background: "#f4f9fd" }}
        ></img>
      )}
      <Work isPop={isPop}></Work>
      {document.body.clientWidth < 640 && (
        <img
          src={topImg}
          style={{
            width: "100%",
            display: "block",
            backgroundColor: "#F4F9FD",
          }}
          className="razdelit"
        ></img>
      )}
      <Footer></Footer>
    </div>
  );
}
