import React, { useState } from "react";
import "./style.css";
import rect from "./Rectangle 599.png";
import arrow from "./arr.svg";
import vector from "./vector.svg";
import dallas from "./Vector 345.png";
import fon from "./fon.svg";
import img1 from "./1.png";
import img2 from "./2.png";
import img3 from "./3.png";
import img4 from "./4.png";
import dal from "./dal.png";
import ReactPlayer from "react-player/lazy";
import { Roll, Bounce, Flip, Fade, Rotate, Zoom } from "react-reveal";
const urls = [
  "videos/dallas.MP4",
  "videos/mkb.MP4",
  "videos/rostur.MP4",
  "videos/rtcom.MP4",
  "videos/rvk.MP4",
];
export const Cases = () => {
  const [slideNum, setSliderNum] = useState(1);
  const [popVideo, openPopVideo] = useState(false);
  const [videoNum, setVideoNum] = useState(0);
  function closeMe(e) {
    if (e.target.id == "gggs") {
      openPopVideo(false);
    }
  }
  return (
    <div className="box">
      <div className="group">
        {/*  {popVideo && (
          <div
            className="popVideo"
            id="gggs"
            onClick={(e) => {
              closeMe(e);
            }}
          >
            <ReactPlayer
              width={document.body.clientWidth > 640 ? "70vw" : "94vw"}
              height={document.body.clientWidth > 640 ? "40vw" : "60vw"}
              url={urls[videoNum]}
              controls
              playing
            />
          </div>
        )} */}
        <div className="overlap">
          <div className="overlap-wrapper">
            <div className="overlap-group">
              <div className="div">
                <Flip cascade left>
                  <div className="text-wrapper">MOTION</div>
                </Flip>
                <div className="realize">
                  <Rotate bottom left>
                    <div className="text-wrapper-3">
                      <div className="lineWrap"></div>
                      реализация
                      <br />
                      от профи
                    </div>{" "}
                  </Rotate>
                  <Flip left>
                    <div className="text-wrapper-2">КЕЙСЫ</div>
                  </Flip>
                </div>
              </div>
              <Zoom right>
                <img className="vector" alt="Vector" src={rect} />
              </Zoom>
            </div>
          </div>
          <div className="casesCont">
            {" "}
            <div
              className="overlap-group-wrapper"
              onClick={() => {
                setVideoNum(0);
                openPopVideo(true);
              }}
            >
              <img src={dal} alt="" />
              <div className="overlap-2">
                <div className="dallas">BABUKH</div>
                <div className="lineWrap"></div>
                <p className="element">
                  Презентационное видео, в котором показали возможности motion
                  design, которые позволяют создавать впечатляющую визуализацию
                </p>
                <div className="group-2">
                  <div className="overlap-group-2">
                    <div className="rectangle" />
                  </div>
                  <div className="text-wrapper-4">
                    Подробнее{" "}
                    <img className="vector-7" alt="Vector" src={arrow} />
                  </div>
                </div>
              </div>
            </div>{" "}
            <div
              className="rightCases"
              style={{
                left: (slideNum - 1) * -99 + "vw",
              }}
            >
              <div
                className="div-wrapper"
                onClick={() => {
                  setVideoNum(1);
                  openPopVideo(true);
                }}
              >
                <img src={img1} alt="" />
                <div className="overlap-3">
                  <div className="text-wrapper-5">МКБ GENERATION</div>
                  <div className="lineWrap"></div>
                  <p className="p">
                    Отчётное видео подчёркивает прозрачность процесса и его
                    информационную доступность
                  </p>
                  <div className="group-5">
                    <div className="overlap-group-3">
                      <div className="rectangle-2" />
                    </div>
                    <div className="text-wrapper-6">
                      Подробнее{" "}
                      <img className="vector-7" alt="Vector" src={arrow} />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="div-wrapper"
                onClick={() => {
                  setVideoNum(2);
                  openPopVideo(true);
                }}
              >
                <img src={img2} alt="" />
                <div className="overlap-3">
                  <div className="text-wrapper-5">РОСТУРИЗМ</div>
                  <div className="lineWrap"></div>
                  <p className="p">
                    Имиджевое видео символизирует современные направления
                    туристического обучения
                  </p>
                  <div className="group-5">
                    <div className="overlap-group-3">
                      <div className="rectangle-2" />
                    </div>
                    <div className="text-wrapper-6">
                      Подробнее{" "}
                      <img className="vector-7" alt="Vector" src={arrow} />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="div-wrapper"
                onClick={() => {
                  setVideoNum(3);
                  openPopVideo(true);
                }}
              >
                <img src={img3} alt="" />
                <div className="overlap-3">
                  <div className="text-wrapper-5">РТ КОММ</div>
                  <div className="lineWrap"></div>
                  <p className="p">
                    Разработка презентационного кейса для подразделения
                    Ростелеком <br />
                  </p>
                  <div className="group-3">
                    <div className="overlap-group-3">
                      <div className="rectangle-2" />
                    </div>
                    <div className="text-wrapper-6">
                      Подробнее{" "}
                      <img className="vector-7" alt="Vector" src={arrow} />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="div-wrapper"
                onClick={() => {
                  setVideoNum(4);
                  openPopVideo(true);
                }}
              >
                <img src={img4} alt="" />
                <div className="overlap-3">
                  <div className="text-wrapper-5">РВК</div>
                  <div className="lineWrap"></div>
                  <p className="p">
                    Презентационное видео для GenerationS — крупнейшего
                    корпоративного акселератора России и СНГ
                  </p>
                  <div className="group-3">
                    <div className="overlap-group-3">
                      <div className="rectangle-2" />
                    </div>
                    <div className="text-wrapper-6">
                      Подробнее{" "}
                      <img className="vector-7" alt="Vector" src={arrow} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {document.body.clientWidth < 640 && (
            <div className="sliderButtons">
              <div
                onClick={() => {
                  slideNum != 1 && setSliderNum(slideNum - 1);
                }}
                style={
                  slideNum != 1
                    ? { border: "3px solid #A4FF00", color: "#fff" }
                    : {}
                }
              >
                ←
              </div>
              <div
                onClick={() => {
                  slideNum != 4 && setSliderNum(slideNum + 1);
                }}
                style={
                  slideNum != 4
                    ? { border: "3px solid #A4FF00", color: "#fff" }
                    : {}
                }
              >
                →
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
