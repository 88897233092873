import "./App.css";
import { Routes, Route } from "react-router-dom";
import Posad from "./pages/Posad";
import { routes } from "./routes";
import "./fonts/stylesheet.css";
import Landing from "./pages/Landing";
import Thanks from "./pages/Thanks/Thanks";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path={routes.main} element={<Posad />} />
        <Route path={routes.landing} element={<Landing />} />
        <Route path={routes.thanks} element={<Thanks />} />
      </Routes>
    </div>
  );
}

export default App;
