import logo from "./logo.svg";
import tg from "./tg.svg";
import wt from "./wt.svg";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import "./style.css";
export default function Footer() {
  return (
    <div className="footer">
      <div className="footer_content">
        <div className="footer_left">
          <img src={logo} alt="" />
          <p className="adress">
            129085 г. Москва, <br /> проспект Мира 101,стр1, офис 20
          </p>
          <a href="mailto:info@babukh-art.ru" className="emails">
            info@babukh-art.ru
          </a>
          <p className="messs">
            Мессенджеры для связи{" "}
            <div>
              <a href="https://t.me/BabukhpartnersMsk" target="_blank">
                <img src={tg} alt="" />
              </a>
              <a href="https://wa.me/89160324032" target="_blank">
                <img src={wt} alt="" />
              </a>
            </div>
          </p>
          <a href="tel:84382425892" className="telik">
            8 438 2425892
          </a>
          <p className="worker">Работаем с 8:00 до 22:00</p>

          <a href="/politic.html" target="_blank" className="rass">
            Политика конфиденциальности
          </a>
        </div>
        <div className="footer_right">
          <YMaps>
            <div>
              <Map
                defaultState={{ center: [55.811953, 37.636374], zoom: 14 }}
                width={"45vw"}
                height={"22vw"}
              >
                <Placemark defaultGeometry={[55.811953, 37.636374]} />
              </Map>
            </div>
          </YMaps>
        </div>
      </div>
    </div>
  );
}
